/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "quill/dist/quill.snow.css";
@import "theme/quill.css";

body {
  // background-color: var(--ion-color-secondary-tint);
  background-image: url(assets/textures/background-dark.png);
  background-repeat: repeat;
  background-size: 64px;
}

ion-input.input-pointer {

  .native-input,
  .input-wrapper {
    cursor: pointer;
  }
}


.pendingChangesAlert {
  .alert-button-group-vertical {
    flex-direction: row;
  }
}

//TODO: cuando se cree un componente para el popover, se debe mover esta clase al componente
ion-popover {
  --min-width: 240px;

  ion-list {
    padding: 5px;
    padding-bottom: 15px;
  }
}

//TODO: cuando se cree un componente para el boton de ayuda, se debe mover esta clase al componente
.ios ion-icon[name=help-circle] {
  padding-left: 16px;
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.large {
  --width: 90%;
  --height: 90%;
  --max-width: 992px;
  --max-height: 744px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.medium {
  --width: 90%;
  --height: 90%;
  --max-width: 572px;
  --max-height: 429px;

  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.small {
  --width: 90%;
  --height: 90%;
  --max-width: 300px;
  --max-height: 415px;

  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  h1 {
    margin: 20px 20px 10px 20px;
  }

}

ion-modal.logs {
  --width: 90%;
  --height: 90%;
  --max-width: 410px;
  --max-height: 400px;

  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  h1 {
    margin: 20px 20px 10px 20px;
  }

}

ion-modal.barcodeScanner {
  contain: layout;
  height: fit-content;
  min-height: 34vw;
}

ion-modal.barcodeScanner.interactive {
  min-height: 0;
}

ion-modal.barcodeScanner::part(content) {
  background: transparent;
  position: absolute;
  top: 0;
  left: 5vw;
  // height: 40vh;
  // width: 35vw;
  height: fit-content;
  width: fit-content;
  // padding: 5px;
  // box-shadow: 0px 0px 10px 5px gray;
  --box-shadow: none;
  filter: drop-shadow(-2px 2px 5px black);
  // padding-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1;
  transform: none;
}

.formSegment {
  height: 330px;
  overflow-y: auto;
}

ion-item.readonly,
ion-checkbox.readonly,
ion-toggle.readonly,
ion-radio.readonly,
ion-range.readonly,
ion-chip.readonly,
ion-button.readonly,
div.readonly {
  cursor: default;
  pointer-events: none;
}


.clickable {
  cursor: pointer;
}

.clickable:not(.ion-color-danger) {
  color: var(--ion-color-primary) !important;
}

.showIn {
  transition: opacity 300ms ease-in-out 200ms, width 300ms ease-in-out, height 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out 200ms, width 300ms ease-in-out, height 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out 200ms, width 300ms ease-in-out, height 300ms ease-in-out;
  -ms-transition: opacity 300ms ease-in-out 200ms, width 300ms ease-in-out, height 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out 200ms, width 300ms ease-in-out, height 300ms ease-in-out;
}

.hiddeOut {
  transition: opacity 300ms ease-in-out, width 300ms ease-in-out 200ms, height 300ms ease-in-out 200ms;
  -webkit-transition: opacity 300ms ease-in-out, width 300ms ease-in-out 200ms, height 300ms ease-in-out 200ms;
  -moz-transition: opacity 300ms ease-in-out, width 300ms ease-in-out 200ms, height 300ms ease-in-out 200ms;
  -ms-transition: opacity 300ms ease-in-out, width 300ms ease-in-out 200ms, height 300ms ease-in-out 200ms;
  -o-transition: opacity 300ms ease-in-out, width 300ms ease-in-out 200ms, height 300ms ease-in-out 200ms;

  pointer-events: none;
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  min-width: 0px !important;
  min-height: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
}

ion-item {
  ion-thumbnail {
    img {
      object-fit: contain;
    }
  }
}

ion-avatar.user {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 5px;
}

ion-chip.message {
  border-radius: 3px;
  height: auto;
  cursor: initial;
}

.ion-no-margin-start {
  --margin-start: 0;
  margin-left: 0;
}

app-information-icons:has(+ [slot=end]) {
  right: 61px;
}

cdk-virtual-scroll-viewport {
  ion-item {
    ion-label {
      text-wrap: balance !important;
    }
  }
}

.alert-imaged {
  .alert-head {
    >* {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.alert-message {
  text-wrap: balance;
  white-space: pre-wrap;
}

.alert-button-secondary {
  color: var(--ion-color-medium) !important;
}

.alert-large .alert-wrapper {
  width: 400px;
  height: 330px;
  max-width: 90%;
  max-height: 80%;
}

.alert-large textarea {
  height: 115px; 
}

ion-toast {
  --white-space: pre;
}

/* Oculta el placeholder si hay algún ion-item presente antes de él */
ion-item~.no-actions {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  cdk-virtual-scroll-viewport {
    ion-item {
      --inner-padding-end: 0;
    }
  }
}